import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/Header"
import Footer from "./footer/Footer"
import "./layout.css"

export default function Layout({ children }) {
  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteNavigation {
              title
              location
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Helmet title={site.siteMetadata.title}>
        <html lang="en" />
      </Helmet>
      <Header
        siteTitle={site.siteMetadata.title}
        siteNavigation={site.siteMetadata.siteNavigation}
      />
      <div>{children}</div>
      <Footer siteTitle={site.siteMetadata.title} />
    </>
  )
}
