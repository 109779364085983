import React from 'react'
import { Link } from 'gatsby'

import DrawerToggleButton from '../sideDrawer/DrawerToggleButton'
import SideDrawer from '../sideDrawer/SideDrawer'

import './Header.css'

class Header extends React.Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => ({
      sideDrawerOpen: !prevState.sideDrawerOpen,
    }))
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }

  render() {
    return (
      <header className="site-header">
        <div className="container">
          <div className="row">
            <div className="twelve columns header-content">
              <Link to="/" className="logo" />
              <ul className="main-navigation">
                {this.props.siteNavigation.map((navItem, index) => (
                  <li key={index}>
                    <Link to={navItem.location}>{navItem.title}</Link>
                  </li>
                ))}
              </ul>
              <DrawerToggleButton click={this.drawerToggleClickHandler} />
              <SideDrawer
                siteNavigation={this.props.siteNavigation}
                backdropClick={this.backdropClickHandler}
                show={this.state.sideDrawerOpen}
              />
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
