import React, { useState } from "react"
import axios from "axios"
import { Form, Field } from "react-final-form"

import "./ContactForm.css"

export default function ContactForm() {
  const [upload, setUpload] = useState(null)

  const showUpload =
    typeof window !== `undefined` && window.location.pathname === "/staff"

  const uploadCv = event => {
    const reader = new FileReader()
    reader.addEventListener(
      "load",
      result => {
        const file = result.currentTarget.result
        const res = file.split(",")

        const base64 = res[1]
        let fileExtension = res[0].split("/")
        fileExtension = fileExtension[1].split(";")

        fileExtension = fileExtension[0]

        if (
          fileExtension ===
          "vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          fileExtension = "docx"
        }

        setUpload({
          attachment: base64,
          fileExtension: fileExtension,
        })
      },
      false
    )
    reader.readAsDataURL(event.target.files[0])
  }

  return (
    <Form
      onSubmit={values => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const phoneRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/

        const errors = {}

        const requiredFields = ["name", "phone", "email", "text"]

        requiredFields.forEach(field => {
          const fieldName = field.toLowerCase()

          if (!values[fieldName]) {
            errors[fieldName] = "Required"
          }

          if (
            fieldName === "email" &&
            values.email &&
            !emailRegex.test(String(values.email).toLowerCase())
          ) {
            errors.email = "Invalid Email"
          }

          if (
            fieldName === "phone" &&
            values.phone &&
            !phoneRegex.test(String(values.phone).toLowerCase())
          ) {
            errors.phone = "Invalid Number"
          }
        })

        if (Object.keys(errors).length) return errors

        if (values.sweet) {
          //honey pot has been filled in, this is a bot, don't actually send the email
          return
        }

        axios.post(
          `${process.env.MAIL_SERVER}`,
          {
            from: "Leda Homecare <website@ledahomecare.co.uk>",
            to: "admin@ledahomecare.co.uk",
            subject: `Message from ${values.name}`,
            text: `${values.name} has sent the following message: ${values.text}. To get in touch with ${values.name}, please call ${values.phone}, email ${values.email}, or reply to this email.`,
            replyTo: values.email,
            domain: "ledahomecare.co.uk",
            html: `<p>${values.name} has sent the following message:</p> <p>${values.text}</p><p>To get in touch with ${values.name}, please call ${values.phone}, email ${values.email}, or reply to this email.</p>`,
            file: upload?.attachment ? JSON.stringify(upload.attachment) : null,
            fileName: upload?.fileExtension
              ? `${values.name} - cv.${upload.fileExtension}`
              : null,
            autoResponse: {
              from: "Leda Homecare <website@ledahomecare.co.uk>",
              to: values.email,
              replyTo: "admin@ledahomecare.co.uk",
              subject: "Message Recieved",
              text:
                "Thank you for getting in touch with Leda Homecare, we will reply shortly. Kind Regards, Leda Homecare",
              html:
                "<p>Thank you for getting in touch with Leda Homecare, we will reply shortly.</p> <p> Kind Regards,</p> <p>Leda Homecare</p> ",
            },
          },
          {
            headers: {
              Authorization:
                `Basic ${
                typeof window === "undefined"
                  ? ""
                  : window.btoa(`Leda Homecare:f16978ff4a636fc337036a55e0c9e4b0`)
                }`
            },
          }
        )
      }}
      render={({ handleSubmit, submitSucceeded }) => (
        <form
          onSubmit={handleSubmit}
          noValidate
          className="contact-form-wrapper"
          id="contact-form"
        >
          <div className="container">
            <div className="row">
              <div className="contact-form">
                <h2 className="center-text">Get In Touch</h2>
                <div className="row">
                  <div className="six columns">
                    <Field name="name">
                      {({ input, meta }) => (
                        <input
                          placeholder="Name"
                          type="text"
                          className={
                            !!meta.touched &&
                              !!meta.submitError &&
                              !meta.dirtySinceLastSubmit
                              ? "error"
                              : ""
                          }
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="six columns">
                    <Field name="phone">
                      {({ input, meta }) => (
                        <input
                          placeholder="Phone"
                          type="number"
                          className={
                            meta.touched &&
                              meta.submitError &&
                              !meta.dirtySinceLastSubmit
                              ? "error"
                              : ""
                          }
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row">
                  <div className="twelve columns">
                    <Field name="email">
                      {({ input, meta }) => (
                        <input
                          type="email"
                          {...input}
                          placeholder="Email"
                          className={
                            meta.touched &&
                              meta.submitError &&
                              !meta.dirtySinceLastSubmit
                              ? "error"
                              : ""
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
                {showUpload && (
                  <div className="row">
                    <div className="four columns">
                      <p>Upload CV (.docx): </p>
                    </div>
                    <div className="columns eight">
                      <input
                        type="file"
                        name="cv"
                        id="cv"
                        accept=".docx"
                        onChange={uploadCv}
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="twelve columns">
                    <Field name="text">
                      {({ input }) => <textarea {...input} />}
                    </Field>
                    <Field name="sweet">
                      {({ input }) => (
                        <input
                          type="text"
                          {...input}
                          style={{ display: "none" }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="twelve columns"
                    style={{ textAlign: "center" }}
                  >
                    <button className="button button-primary" type="submit">
                      Send Message
                    </button>
                  </div>
                </div>

                {submitSucceeded && (
                  <div className="contact-confirmed">
                    <p>
                      Your message has been sent, we will get in touch shortly.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    />
  )
}
