import React from 'react'
import ScrollUpButton from 'react-scroll-up-button'

import map from '../../images/map.png'

import UKHC from '../../images/main/UKHCA-white.png'
import careQuality from '../../images/main/care-quality-white.png'

import Facebook from '../../images/icons/iconmonstr-facebook-6.svg'
import Twitter from '../../images/icons/iconmonstr-twitter-3.svg'

import './Footer.css'
import ContactForm from '../contactForm/ContactForm'

const Footer = (props) => (
  <div>
    <ContactForm />
    <div
      className="map"
      style={{ backgroundImage: `url(${map})`, height: '50vh' }}
    />
    <ScrollUpButton />
    <footer>
      <div className="container">
        <div className="row">
          <div className="six columns" style={{ margin: '25px 0' }}>
            <div>
              <p>
                <span>&copy;</span>
                {` ${new Date().getFullYear()} ${props.siteTitle}`}
              </p>
              <p>
                Website By{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://daffodil-marketing.com/services/website-design"
                >
                  Daffodil Marketing
                </a>
              </p>
              <p>
                {' '}
                <a href="/privacy">Privacy Notice</a>
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <a href="https://www.facebook.com/Ledahomecare/">
                <div
                  className="social-icon"
                  style={{ backgroundImage: 'url(' + Facebook + ')' }}
                />
              </a>
              <a href="https://twitter.com/LedaHomecare">
                <div
                  className="social-icon"
                  style={{ backgroundImage: 'url(' + Twitter + ')' }}
                />
              </a>
            </div>
          </div>
          <div
            className="six columns"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              className="healthcare-logo"
              style={{ backgroundImage: 'url(' + UKHC + ')' }}
            />
            <div
              className="healthcare-logo"
              style={{ backgroundImage: 'url(' + careQuality + ')' }}
            />
          </div>
        </div>
      </div>
    </footer>
  </div>
)

export default Footer
